import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonGrid,
  IonBackButton,
  IonSpinner,
  IonDatetime,
  IonRow,
  IonCol,
  useIonViewWillLeave,
} from "@ionic/react";
import React, { useState } from "react";
import {
  cash as cashIcon,
  people as peopleIcon,
  calendar as calendarIcon,
} from "ionicons/icons";
import { gql, useQuery, useMutation } from "@apollo/client";
import AuthService from "../../services/auth.service";
import { useParams, useHistory } from "react-router";
import {
  getRandomColor,
  createImageFromInitials,
} from "../../components/Utils";
import Moment from "moment";
import "./Tab1.css";
import { Toast } from "@capacitor/toast";
import { useTranslation } from "react-i18next";

const GET_AREA_COMUN = gql`
  query areaComun($areaComunId: String) {
    viewer {
      areaComun(areaComunId: $areaComunId) {
        id
        nombre
        costo
        cupo
        impedirMorosos
        descripcion
        reglamentoUso
        enServicio
        autoAprobacion
      }
    }
  }
`;

const CREATE_RESERVACION = gql`
  mutation CreateAreaComunReservaMutation(
    $areacomunreservaData: AreaComunReservasInput!
  ) {
    createAreaComunReserva(areacomunreservaData: $areacomunreservaData) {
      areacomunreserva {
        id
      }
    }
  }
`;

interface PageProps {
  pageName: string | undefined;
}

const AreaComunPage: React.FC<PageProps> = ({ pageName }) => {
  const { t } = useTranslation();
  const { areaComunId } = useParams<{ areaComunId: string }>();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [showReservaForm, setShowReservaForm] = useState(false);
  const [condominioId, setCondominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0,
  );
  const [isLoading, setIsLoading] = useState(false);
  //const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState<any[]>([]);
  const history = useHistory();

  const { loading, error, data } = useQuery(GET_AREA_COMUN, {
    variables: { areaComunId },
    onCompleted(data) {},
    onError(error) {
      //reset();
    },
  });

  const [
    createReservacion,
    {
      data: dataReservacion,
      loading: loadingReservacion,
      error: errorReservacion,
      reset,
    },
  ] = useMutation(CREATE_RESERVACION, {
    onError(error) {
      reset();
    },
    onCompleted() {
      history.push({
        pathname: `/page/areascomunes/areascomunes`,
      });
    },
  });

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const showToastNotification = async (textNotification: string) => {
    await Toast.show({
      text: textNotification,
    });
  };

  const onChanged = (dates: any) => {
    //setSelectedDates(dates);
    dates.map((value: any, index: any) => {
      setSelectedDates((current) =>
        current.concat({
          id: areaComunId,
          fecha: value,
          cupo: data.viewer.areaComun.cupo,
          costo: data.viewer.areaComun.costo,
          nombre: data.viewer.areaComun.nombre,
        }),
      );
    });
  };

  const handleShowReservaForm = () => {
    if (selectedDates.length > 0) {
      setShowReservaForm(true);
    } else {
      showToastNotification("Seleccione una Fecha para continuar");
    }
  };

  const handleClickCreateReservacion = () => {
    selectedDates.map((value: any, index: any) => {
      createReservacion({
        variables: {
          areacomunreservaData: {
            areaComunId: areaComunId,
            fechaInicio: new Date(value.fecha),
            fechaFin: new Date(value.fecha),
            costo: value.costo,
          },
        },
      });
    });
  };

  useIonViewWillLeave(() => {
    setShowReservaForm(false);
    setSelectedDates([]);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          {loading ? (
            <IonSpinner name="dots"></IonSpinner>
          ) : (
            <IonTitle>
              {data.viewer.areaComun.nombre} [{t("amenity")}]
            </IonTitle>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid fixed={true}>
          {loading ? (
            <div className="spin">
              <IonSpinner></IonSpinner>
            </div>
          ) : !showReservaForm ? (
            <>
              <IonCard key={1}>
                <img
                  style={{ maxWidth: "100%", height: "186px", width: "100%" }}
                  src={createImageFromInitials(
                    240,
                    data.viewer.areaComun.nombre,
                    getRandomColor(),
                  )}
                />
                <IonCardHeader>
                  <IonCardSubtitle>
                    {data.viewer.areaComun.descripcion}
                  </IonCardSubtitle>
                  <IonCardTitle>{data.viewer.areaComun.nombre}</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  <div
                    className="preview"
                    dangerouslySetInnerHTML={{
                      __html: data.viewer.areaComun.reglamentoUso,
                    }}
                  ></div>
                </IonCardContent>
                <IonItem>
                  <IonIcon icon={cashIcon} slot="start" />
                  <IonLabel>
                    {t("price")}{" "}
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(data.viewer.areaComun.costo)}{" "}
                    {t("by-day")}
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonIcon icon={peopleIcon} slot="start" />
                  <IonLabel>
                    {t("quota")} {data.viewer.areaComun.cupo}{" "}
                    {data.viewer.areaComun.cupo > 1 ? t("people") : t("person")}
                  </IonLabel>
                </IonItem>
              </IonCard>

              <IonCard key={2}>
                <IonDatetime
                  size="cover"
                  multiple={true}
                  presentation="date"
                  locale="es-ES"
                  onIonChange={(event) => onChanged(event.detail.value)}
                >
                  <span slot="title">
                    {t("select-the-dates-you-want-to-reserve")}
                  </span>
                </IonDatetime>
                <IonButton
                  onClick={handleShowReservaForm}
                  fill="outline"
                  expand="block"
                >
                  <IonIcon icon={calendarIcon} slot="start" />
                  {t("book")} {t("amenity")}
                </IonButton>
              </IonCard>
            </>
          ) : (
            <IonCard key={3}>
              <IonRow>
                <IonCol size="3">{t("date")}</IonCol>
                <IonCol size="3">{t("invoice-number")}</IonCol>
                <IonCol size="3">{t("quota")}</IonCol>
                <IonCol size="3">{t("price")}</IonCol>
              </IonRow>
              {selectedDates.map((input: any, index: any) => (
                <IonRow key={index}>
                  <IonCol size="3">
                    {Moment(input.fecha).format("DD-MMM-YYYY")}
                  </IonCol>
                  <IonCol size="3">{input.recibo}</IonCol>
                  <IonCol size="3">
                    {input.cupo} {input.cupo > 1 ? "personas" : "persona"}
                  </IonCol>
                  <IonCol size="3">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(input.costo)}{" "}
                    MXN
                  </IonCol>
                </IonRow>
              ))}

              {loadingReservacion ? (
                <div className="spin">
                  <IonSpinner></IonSpinner>
                </div>
              ) : (
                <IonButton
                  className="custom-button"
                  expand="block"
                  fill="outline"
                  onClick={handleClickCreateReservacion}
                >
                  <IonIcon icon={calendarIcon} slot="start" />
                  {t("request-reservation")}
                </IonButton>
              )}
            </IonCard>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AreaComunPage;
