import axios from "axios";
import { env_var } from "../config/env";
import { history } from "..//helpers/history";
import { getFormDataHeader, getHeaderInfo } from "../helpers/tokenCreator";
import { removeTokens } from "./localStorage";

const handleResponse = (response: any) => {
  if (response.status === 401) {
    removeTokens();
  }

  // Check if response.data exists and if response.data.status is "OK"
  if (!response.data || response.data.status !== "OK") {
    return response.data || { status: "ERROR", message: response };
  }

  return response;
};

export const post = async function (url: string, body: any) {
  let header = await getHeaderInfo();
  try {
    let resp = await axios.post(env_var.BACKEND_URL + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    if (err) {
      const message = err.response && err.response.data ? err.response.data.message : err.message
      throw handleResponse(message);
    } else {
      throw handleResponse("An unknown error occurred.");
    }
  }
};

export const forgotPassword = async function (url: string, body: any) {
  let header = await getHeaderInfo();
  try {
    let resp = await axios.post(env_var.BACKEND_URL + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    if (err) {
      const message = err.response && err.response.data ? err.response.data.message : err.message
      throw handleResponse(message);
    } else {
      throw handleResponse("An unknown error occurred.");
    }
  }
};

export const get = async function (url: any, params: any = {}) {
  let header = await getHeaderInfo();
  try {
    let resp = await axios.get(url, { ...header, params });
    return handleResponse(resp);
  } catch (err: any) {
    if (err) {
      const message = err.response && err.response.data ? err.response.data.message : err.message
      throw handleResponse(message);
    } else {
      throw handleResponse("An unknown error occurred.");
    }
  }
};

export const getWithRouteParams = async function (url: any) {
  let header = await getHeaderInfo();
  try {
    let resp = await axios.get(env_var.BACKEND_URL + url, { ...header});
    return handleResponse(resp);
  } catch (err: any) {
    if (err) {
      const message = err.response && err.response.data ? err.response.data.message : err.message
      throw handleResponse(message);
    } else {
      throw handleResponse("An unknown error occurred.");
    }
  }
};

export const put = async function (body: any, url: any) {
  let header = await getHeaderInfo();

  try {
    let resp = await axios.put(env_var.BASE_URL + url, body, header);

    return handleResponse(resp);
  } catch (err) {
    if (err instanceof Error) {
      throw handleResponse(err.message);
    } else {
      throw handleResponse("An unknown error occurred.");
    }
  }
};

export const deleteApi = async function (url: any) {
  let header = await getHeaderInfo();

  try {
    let resp = await axios.delete(env_var.BASE_URL + url, header);

    return handleResponse(resp);
  } catch (err) {
    if (err instanceof Error) {
      throw handleResponse(err.message);
    } else {
      throw handleResponse("An unknown error occurred.");
    }
  }
};

export const postImage = async function (url: string, body: any) {
  let header = await getFormDataHeader();
  var formData = new FormData();
  formData.append("file", body);
  try {
    let resp = await axios.put(env_var.BASE_URL + url, formData, header);
    return handleResponse(resp);
  } catch (err) {
    if (err instanceof Error) {
      throw handleResponse(err.message);
    } else {
      throw handleResponse("An unknown error occurred.");
    }
  }
};
