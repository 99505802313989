import { useHistory } from "react-router-dom";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonSpinner,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonLabel,
  useIonViewWillEnter,
  IonBadge,
  IonList,
} from "@ionic/react";
import React, { useState, useRef } from "react";
import { add as addIcon } from "ionicons/icons";
import "moment/locale/es";
import { useTranslation } from "react-i18next";
import "./Pages.css";

interface PageProps {
  pageName: string | undefined;
}

const ProductPage: React.FC<PageProps> = ({ pageName }, location) => {
  const history = useHistory();
  const fileInput = useRef(null);
  const { t } = useTranslation();
  const [currentUser] = useState({ condominioId: 1, id: 1 });
  const [condominioId, setCondominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0,
  );

  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const [lang, setLang] = useState<string>(
    localStorage.getItem("langSelect")
      ? localStorage.getItem("langSelect")!
      : "en",
  );


  function setPreview(file: any) {
    setFile(file.target.files[0]);
  }

  useIonViewWillEnter(() => {
    //refetch();
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons>
            <IonMenuButton />
            <IonTitle>{t("products")}</IonTitle>
            <IonButton
              slot="end"
              fill="clear"
              routerLink={"/page/products/new"}
            >
              <IonIcon icon={addIcon} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {false ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <>
            <IonCard>
              <img
                alt="Silhouette of mountains"
                src="https://ionicframework.com/docs/img/demos/card-media.png"
                style={{ maxWidth: "100%", height: "186px", width: "40%" }}
              />
              <IonCardHeader>
                <IonCardTitle>Productos</IonCardTitle>
                <IonCardSubtitle>Datos de Contacto</IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent>Nombre</IonCardContent>

              <IonCardContent>Dirección</IonCardContent>
            </IonCard>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ProductPage;
