import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getMaterialList } from "../../services/materialService";
import { RootState } from "../../store";
import { IMaterial } from "./interface";

export interface IMaterialsList {
  isLoadingMaterials: boolean;
  materialList?: IMaterial[];
  error?: string;
}

const initialState: IMaterialsList = { isLoadingMaterials: false };

const materialListSlice = createSlice({
  name: "materialList",
  initialState,
  reducers: {
    start: (state) => {
      state.isLoadingMaterials = true;
      state.error = undefined;
    },
    success: (state, action: PayloadAction<IMaterial[]>) => {
      state.materialList = action.payload;
      state.isLoadingMaterials = false;
      state.error = undefined;
    },
    error: (state, action: PayloadAction<string>) => {
      state.isLoadingMaterials = false;
      state.error = action.payload;
    },
    createItem: (state, action) => {
      if (state.materialList)
        state.materialList.push(action.payload);
    },
    updateItem: (state, action) => {
      const updatedItem = action.payload;
      if (state.materialList) {
        const index = state.materialList.findIndex(item => item.id === updatedItem.id);
        if (index !== -1) {
          state.materialList[index] = updatedItem;
        }
      }
    },
    deleteItem: (state, action) => {
      const itemId = action.payload;
      if (state.materialList)
        state.materialList = state.materialList.filter(item => item.id !== itemId);
    }
  },
});

export const fetchMaterials = () => async (dispatch: any) => {
  dispatch(start());
  try {
    const materialLists = await getMaterialList();
    dispatch(success(materialLists));
  } catch (err) {
    if (err instanceof Error) {
      dispatch(error(err.message));
    } else {
      dispatch(error("An unknown error occurred."));
    }
  }
};

export const updateMaterials = () => async (dispatch: any) => {
  dispatch(start());
  try {
    const materialLists = await getMaterialList();
    dispatch(updateItem(materialLists));
  } catch (err) {
    if (err instanceof Error) {
      dispatch(error(err.message));
    } else {
      dispatch(error("An unknown error occurred."));
    }
  }
};

export const { start, success, error, updateItem } = materialListSlice.actions;

export const selectMaterialLists = (state: RootState) => state.materialList;

export const materialsListReducer = materialListSlice.reducer;
