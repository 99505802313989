import React from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, useHistory } from "react-router-dom";
import authService from "../services/auth.service";
import { persistor } from '../store';

const LogOutPage: React.FC = () => {
  const history = useHistory();

  const handleLogout = async () => {
    authService.logout(); // Perform logout logic
    // Clear persisted state
    persistor.purge().then(() => {
      window.location.reload();
    });
  };

  React.useEffect(() => {
    handleLogout();
  }, []);
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tab 1</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Redirect to="/" />
      </IonContent>
    </IonPage>
  );
};

export default LogOutPage;
