import React, { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonGrid,
  IonButtons,
  IonMenuButton,
  IonSpinner,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonBadge,
  useIonAlert,
} from "@ionic/react";
import { trash as trashIcon } from "ionicons/icons";
import "./Tab1.css";
import { gql, useQuery, useMutation } from "@apollo/client";
import AuthService from "../../services/auth.service";
import Moment from "moment";
import { useTranslation } from "react-i18next";

const GET_USER = gql`
  query usuario($usuarioId: String) {
    viewer {
      usuario(usuarioId: $usuarioId) {
        id
        reservas {
          edges {
            node {
              id
              fechaInicio
              estatus
              areaComun {
                id
                nombre
              }
            }
          }
        }
      }
    }
  }
`;

const DELETE_RESERVACION = gql`
  mutation deleteAreaComunReserva($id: ID!) {
    deleteAreaComunReserva(id: $id) {
      success
      areacomunreserva {
        id
      }
    }
  }
`;

const pagoCompletoStyle = {
  backgroundColor: "rgba(60, 179, 113, 0.2)",
};
const pagoIncompletoStyle = {
  backgroundColor: "rgba(255, 99, 71, 0.2)",
};

const ReservacionesTab: React.FC = () => {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [condominioId, setCondominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0,
  );

  const [cuotasPendientes, setCuotasPendientes] = useState<any[]>([]);

  const [presentAlert] = useIonAlert();

  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { usuarioId: currentUser.id },
    onCompleted(data) {
      setCuotasPendientes([]);
      data.viewer.usuario.reservas.edges.map((input: any, index: any) => {
        setCuotasPendientes((current) => [
          ...current,
          {
            id: input.node.id,
            nombreArea: input.node.areaComun.nombre,
            fecha: input.node.fechaInicio,
            estatus: input.node.estatus,
            //cargo: input.node.monto,
          },
        ]);
      });
    },
    onError(error) {
      //reset();
    },
  });

  const [
    deleteReservacion,
    {
      data: dataReservacion,
      loading: loadingReservacion,
      error: errorReservacion,
      reset,
    },
  ] = useMutation(DELETE_RESERVACION, {
    onError(error) {
      reset();
    },
    onCompleted() {
      refetch();
    },
  });

  function getColor(estatus: string) {
    switch (estatus) {
      case "P":
        return { color: "warning", label: t("pending") };
      case "A":
        return { color: "success", label: t("approved") };
      case "T":
        return { color: "green", label: t("pending") };
      case "C":
        return { color: "green", label: t("cancelled") };
      default:
        return { color: "green", label: t("cancelled") };
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle size="large">
            {t("my-bookings")} [{t("amenities")}]
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid fixed={true}>
          {loading ? (
            <div className="spin">
              <IonSpinner></IonSpinner>
            </div>
          ) : (
            <>
              <IonRow>
                <IonCol size="3">{t("amenity")}</IonCol>
                <IonCol size="3">{t("date")}</IonCol>
                <IonCol size="3">{t("status")}</IonCol>
                <IonCol size="3">{t("cancel")}</IonCol>
              </IonRow>
              {cuotasPendientes.map((input: any, index: any) => (
                <IonRow>
                  <IonCol size="3">{input.nombreArea}</IonCol>
                  <IonCol size="3">
                    {Moment(input.fecha).format("DD-MMM-YY")}
                  </IonCol>
                  <IonCol size="3">
                    <IonItem>
                      <IonLabel>{getColor(input.estatus).label}</IonLabel>
                      <IonBadge color={getColor(input.estatus).color}>
                        {input.estatus}
                      </IonBadge>
                    </IonItem>
                  </IonCol>
                  <IonCol size="3">
                    <IonButton
                      fill="outline"
                      expand="block"
                      color={"danger"}
                      onClick={(event) =>
                        presentAlert({
                          header: `Estas seguro de eliminar la reservación de ${input.nombreArea}?`,
                          cssClass: "custom-alert",
                          buttons: [
                            {
                              text: "Cancelar",
                              role: "cancel",
                              cssClass: "alert-button-cancel",
                            },
                            {
                              text: "Aceptar",
                              role: "confirm",
                              cssClass: "alert-button-confirm",
                              handler: () => {
                                deleteReservacion({
                                  variables: { id: input.id },
                                });
                              },
                            },
                          ],
                        })
                      }
                    >
                      <IonIcon icon={trashIcon} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              ))}
            </>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ReservacionesTab;
