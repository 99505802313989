import { getAccessToken } from "./localStorage";
import { env_var } from "../config/env";
import { userLogin } from "./userService";

export interface AuthPayload {
  email: string;
  password: string;
  username: string;
}

export const authenticate = async (data: AuthPayload) => {
  const response = await userLogin({
    email: data.email,
    password: data.password
  })
  console.log('response', response)
  if (response) {
    return {
      status: true,
      data: {
        user: response.user,
        token: response.access_token,
        refreshToken: response.access_token,
      },
    };
  } else {
    return { status: false, data: "Something went wrong" };
  }
};

export const isAuthenticated = (): boolean => {
  return getAccessToken() ? true : false;
};
