import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonSpinner,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonLabel,
  IonList,
  IonTextarea,
  IonBackButton,
  IonImg,
} from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import { send as sendIcon, attach as attachIcon } from "ionicons/icons";
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useTranslation, withTranslation } from "react-i18next";

import "./Pages.css";

const CREATE_COMPROBANTE = gql`
  mutation createComprobanteMutation($comprobanteData: ComprobantesInput!) {
    createComprobante(comprobanteData: $comprobanteData) {
      comprobante {
        id
      }
    }
  }
`;

const SINGLE_UPLOAD_MUTATION = gql`
  mutation uploadFileMutation($file: Upload!) {
    uploadFile(file: $file) {
      success
      fileName
    }
  }
`;

interface PageProps {
  pageName: string | undefined;
}

const SubirComprobantePage: React.FC<PageProps> = ({ pageName }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const hiddenFileInput = useRef(null);
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [condominioId, setCondominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0,
  );
  const [image, setImage] = useState<string>();
  const [file, setFile] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const [text, setText] = useState<string>();

  const [
    uploadRequest,
    { data: DataUpload, loading: LoadingUpload, error: ErrorUpload },
  ] = useMutation(SINGLE_UPLOAD_MUTATION, {
    onCompleted(data) {
      const fileName = `https://admin.vittapp.com/app-assets/uploads/${data.uploadFile.fileName}`;

      setImage(fileName);
    },
  });

  const [createComprobante, { data, loading, error, reset }] = useMutation(
    CREATE_COMPROBANTE,
    {
      onError(error) {
        reset();
      },
      onCompleted() {
        history.push({
          pathname: `/page/pagos`,
          state: {
            response: "success",
          },
        });
        setImage("");
        setText("");
      },
    },
  );

  const uploadFile = async () => {
    if (!file) return;
    try {
      const res = await uploadRequest({
        variables: { file },
        //,refetchQueries: [{ query: GET_PHOTOS_QUERY }],
      });
      if (res.data) {
        setImageChange(true);
        //setFile(null);
        setTimeout(() => console.log("Succes"), 300);
      }
    } catch (err) {
      console.error(err);
    }
  };

  function setPreview(file: any) {
    setFile(file.target.files[0]);
    console.log("");
  }

  function onClickCreateComprobante() {
    createComprobante({
      variables: {
        comprobanteData: {
          comentario: text,
          urlImagen: image,
        },
      },
    });
  }

  useEffect(() => {
    uploadFile();
  }, [file]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("new-payment-proof")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{t("upload-vouchers")}</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel position="floating">{t("comments")}</IonLabel>
                <IonTextarea
                  value={text}
                  onIonChange={(e) => setText(e.detail.value!)}
                ></IonTextarea>
              </IonItem>
              <IonItem>
                <input
                  ref={hiddenFileInput}
                  hidden
                  type="file"
                  accept="image/*"
                  onChange={(e) => setPreview(e)}
                  onClick={() => {
                    console.log("onClick");
                  }}
                ></input>
                <IonButton
                  fill="outline"
                  slot="start"
                  onClick={() => {
                    // @ts-ignore
                    hiddenFileInput?.current?.click();
                    // setBackgroundOption(BackgroundOptionType.Gradient);
                  }}
                >
                  <IonIcon icon={attachIcon} slot="start" />
                  {t("select-file")}
                </IonButton>
              </IonItem>
            </IonList>
          </IonCardContent>
          {LoadingUpload ? (
            <IonItem>
              <div className="spin">
                <IonSpinner></IonSpinner>
              </div>
            </IonItem>
          ) : (
            image && (
              <IonItem>
                <IonImg
                  style={{ maxWidth: "100%", height: "250px", width: "250px" }}
                  src={`${image}`}
                />
              </IonItem>
            )
          )}

          <IonButton
            fill="outline"
            expand="block"
            onClick={() => {
              onClickCreateComprobante();
            }}
          >
            <IonIcon icon={sendIcon} slot="end"></IonIcon>
            {t("send")}
          </IonButton>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(SubirComprobantePage);
