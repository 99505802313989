import { get, post, getWithRouteParams } from "./apiService";
import { env_var } from "../config/env";

export const getUserList = async function () {
  return await get(`${env_var.BASE_URL}users`);
};

export const forgotPassword = async function (body: any) {
  return await post(`users/forgot_password`, body);
};

export const resetPassword = async function (token: string) {
  return await getWithRouteParams(`reset_password/${token}`);
};

export const userLogin = async function (body: any) {
  return await post(`users/login`, body);
};


