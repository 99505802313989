import React, { useState, useEffect } from 'react';
import {
  IonHeader,
  IonList,
  IonListHeader,
  IonLabel,
  IonContent,
  IonInput,
  IonButton,
  IonText,
  IonItemGroup,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  useIonToast,
  IonSpinner
} from '@ionic/react';
import "./ResetPassword.css";
import { useTranslation, withTranslation } from "react-i18next";
import { send as sendIcon, alert as alertIcon } from "ionicons/icons";
import { forgotPassword, resetPassword } from "../../services/userService";

interface ResetPasswordProps {
  handleClose: () => void;
}


const ResetPassword: React.FC<ResetPasswordProps> = ({ handleClose }) => {
  const [model, setModel] = useState({ email: '', new_password: '' });
  const [flag, setFlag] = useState(1);
  const [present] = useIonToast();
  const [loading, setLoading] = useState(false);

  const presentToast = (position: 'top' | 'middle' | 'bottom', color: 'success' | 'warning' | 'danger', message: string) => {
    present({
      message: message,
      duration: 1500,
      position: position,
      color: color

    });
  };

  // Equivalent of Angular's ngOnInit
  useEffect(() => {
    getData();
  }, [model]);

  const getData = () => {
    let data = {};
    if (model.email === '' && model.new_password === '') {
      data = {
        title: 'Forgot password',
        subTitle: 'Enter your email for the verification process.',
        button: 'VERIFY',
      };
      setFlag(1);
    } else {
      data = {
        title: 'Reset password',
        subTitle: 'Enter your new password, must be at least 8 characters long.',
        button: 'SAVE',
      };
      setFlag(2);
    }
    console.log(data);
    return data;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault(); 
    setLoading(true);
    setModel({
      email: e.target.email.value || '',
      new_password: e.target.new_password.value || '',
    });
  
    try {
      const resetPasswordResponse = await forgotPassword(model);
      handleClose();
      presentToast('bottom', 'success', resetPasswordResponse.message);
      setLoading(false);
    } catch (error: any) {
      presentToast('bottom', 'danger', error.message);
      setLoading(false);
    }
  };


  return (
    <>
      <IonContent fullscreen={true}>
              <form onSubmit={onSubmit}>
                <IonList lines="none">
                  <IonItemGroup className="ion-padding-horizontal ion-margin">
                    <IonInput
                      label="Correo electrónico"
                      labelPlacement="stacked"
                      fill="outline"
                      type="email"
                      name="email"
                      value={model.email}
                      onIonChange={(e) =>
                        setModel({ ...model, email: e.detail.value! })
                      }
                      placeholder="Introduzca correo electrónico"
                      required
                    />
                  </IonItemGroup>
                  <IonItemGroup className="ion-padding-horizontal ion-margin">
                    <IonInput
                      label="Contraseña"
                      labelPlacement="stacked"
                      fill="outline"
                      type="text"
                      name="new_password"
                      value={model.new_password}
                      onIonChange={(e) =>
                        setModel({ ...model, new_password: e.detail.value! })
                      }
                      placeholder="Nueva contraseña"
                      required
                    //minLength={8}
                    />
                  </IonItemGroup>
                  <IonItemGroup  className="ion-padding-horizontal ion-margin">
                    <IonButton
                      expand="block"
                      size="default"
                      color="primary"
                      type="submit"
                      fill="outline"
                      disabled={loading}
                      >
                        
                        {!loading && <IonIcon icon={sendIcon} slot="end" />}
                        {loading && <IonSpinner slot="end" style={{width: '18.9px', height: '18.9px'}}/>}
                        {loading ? "Enviando" : "Enviar"}
                    </IonButton>
                  </IonItemGroup>
                </IonList>
              </form>
      </IonContent>
    </>
  );
};

export default withTranslation()(ResetPassword);
