import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonDatetime,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonSpinner,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonLabel,
  IonSelectOption,
  IonList,
  IonTextarea,
  IonBackButton,
  IonSelect,
  IonImg,
  IonAccordion,
  IonAccordionGroup,
  IonGrid,
  IonCol,
  IonRow,
  IonInput,
  IonCheckbox,
  IonPopover,
  IonToggle,
} from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import {
  send as sendIcon,
  attach as attachIcon,
  trashOutline,
  informationCircle,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Pages.css";


interface PageProps {
  pageName: string | undefined;
}

const CreateProductPage: React.FC<PageProps> = ({ pageName }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const hiddenFileInput = useRef(null);
  const [currentUser] = useState({ condominioId: 1, id: 1 });
  const [condominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0,
  );
  const [image, setImage] = useState<string>();
  const [file, setFile] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const [text, setText] = useState<string>();
  const [name, setName] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [cellPhone, setcellPhone] = useState<string>();
  const [fechaIncidencia, setFechaIncidencia] = useState<string>();
  const [incidencia, setIncidencia] = useState("");
  const [lang, setLang] = useState<string>(
    localStorage.getItem("langSelect")
      ? localStorage.getItem("langSelect") === "es"
        ? "es-ES"
        : "en-US"
      : "en-US",
  );
  const [basic, setBasic] = useState<string>();
  const [town, setTown] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [state, setState] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [tradename, setTradename] = useState<string>();
  const [website, setWebsite] = useState<string>();
  const [tags, setTags] = useState<string>();
  const [typeofcontact, setTypeContact] = useState<string>();
  const [idNumber, setIdNumber] = useState<string>();
  const [bank, setBank] = useState<string>();
  const [iban, setIban] = useState<string>();
  const [SWIFT, setSWIFT] = useState<string>();
  const [mandateReference, setMandateReference] = useState<string>();
  const [mandatedate, setMandateDate] = useState<string>();
  const [languaje, setLanguaje] = useState<string>();
  const [currency, setCurrency] = useState<string>();
  const [salesaccount, setSalesAccount] = useState<string>();
  const [shoppingaccount, setShoppingAccount] = useState<string>();
  const [methodofpayment, setMethodoofpayment] = useState<string>();
  const [expirationat, setExpirationAt] = useState<string>();
  const [discount, setDiscount] = useState<string>();
  const [rate, setRate] = useState<string>();
  const [payday, setPayday] = useState<string>();
  const [reference, setReference] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [subtotal, setSubtotal] = useState<string>();
  const [total, setTotal] = useState<number>();
  const [SKU, setSKU] = useState<string>();
  const [factorycode, setFactoryCode] = useState<string>();
  const [barcode, setBarCode] = useState<string>();
  const [weightkg, setWeightkg] = useState<number>();
  const [stock, setStock] = useState<number>();
  const [cost, setCost] = useState<number>();


  const uploadFile = async () => {
    if (!file) return;
  };

  function setPreview(file: any) {
    setFile(file.target.files[0]);
    console.log("");
  }


  const onChangeFechaInicio = (dates: any) => {
    setFechaIncidencia(dates);
  };

  const createBitacoraVisitanteSubmit = () => {

  };

  useEffect(() => {
    uploadFile();
  }, [file]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("products")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {false ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{t("new-product")}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              <IonAccordionGroup>
                <IonAccordion value="first">
                  <IonItem slot="header" color="light">
                    <IonLabel> {t("basic")}</IonLabel>
                  </IonItem>

                  <div className="ion-padding" slot="content">
                    <IonList>
                      <IonItem>
                        <IonLabel color="secondary" class="ion-text-wrap">
                          <p>
                            {" "}
                            {t("product-information")}
                          </p>
                        </IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonInput
                          label={t("name")}
                          value={name}
                          onIonChange={(e) =>
                            setName(e.detail.value!.toString())
                          }
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonInput
                          label={t("description")}
                          value={description}
                          onIonChange={(e) =>
                            setDescription(e.detail.value!.toString())
                          }
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>
                    </IonList>
                  </div>
                </IonAccordion>

                <IonAccordion value="second">
                  <IonItem slot="header" color="light">
                    <IonLabel> {t("rates")}</IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <IonList>
                      <IonItem>
                        <IonLabel color="medium">
                          {t(
                            "the-total-or-subtotal-will-be-calculated-automatically",
                          )}
                        </IonLabel>
                      </IonItem>

                      <IonItem>
                        <IonLabel color="medium"> {t("main-rate")}</IonLabel>
                      </IonItem>

                      <IonItem>
                        <IonInput
                          inputmode="numeric"
                          type="number"
                          value={subtotal}
                          placeholder="00000.00"
                          onIonChange={(e) =>
                            setSubtotal(e.detail.value!.toString())
                          }
                          label={t("Subtotal")}
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                          label={t("sales-tax")}
                          labelPlacement="floating"
                        >
                          <IonSelectOption value="sales-tax 20%">
                            {t("sales-tax 20%")}
                          </IonSelectOption>
                          <IonSelectOption value="sales-tax 10%">
                            {t("sales-tax 20%")}
                          </IonSelectOption>
                          <IonSelectOption value="sales-tax 0%">
                            {t("sales-tax 0%")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonInput
                          type="number"
                          value={total}
                          placeholder="00000.00"
                          onIonChange={(e) =>
                            setTotal(parseInt(e.detail.value!.toString(), 10))
                          }
                          label={t("total")}
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>
                    </IonList>
                  </div>
                </IonAccordion>

                <IonAccordion value="third">
                  <IonItem slot="header" color="light">
                    <IonLabel> {t("inventory")}</IonLabel>
                  </IonItem>

                  <div className="ion-padding" slot="content">
                    <IonList>
                      <IonItem>
                        <IonLabel> {t("SKU")}</IonLabel>
                        <IonButton slot="end" fill="clear">
                          <IonIcon icon={informationCircle} slot="icon-only" />
                        </IonButton>
                        <IonPopover
                          trigger="hover-trigger"
                          triggerAction="hover"
                        >
                          <IonContent class="ion-padding">
                            {t("Stock-Kipping-Unit")}
                          </IonContent>
                        </IonPopover>
                      </IonItem>

                      <IonItem>
                        <IonInput
                          value={SKU}
                          onIonChange={(e) =>
                            setSKU(e.detail.value!.toString())
                          }
                          label={t("SKU")}
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonInput
                          value={factorycode}
                          onIonChange={(e) =>
                            setFactoryCode(e.detail.value!.toString())
                          }
                          label={t("factory-code")}
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonInput
                          value={barcode}
                          onIonChange={(e) =>
                            setBarCode(e.detail.value!.toString())
                          }
                          label={t("barcode")}
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonInput
                          type="number"
                          value={weightkg}
                          placeholder="00000.00"
                          onIonChange={(e) =>
                            setWeightkg(
                              parseInt(e.detail.value!.toString(), 10),
                            )
                          }
                          label={t("weight-kg")}
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel> {t("manage-stock")}</IonLabel>
                        <IonToggle slot="end"></IonToggle>
                      </IonItem>

                      <IonItem>
                        <IonInput
                          type="number"
                          value={stock}
                          placeholder="00000.00"
                          onIonChange={(e) =>
                            setStock(parseInt(e.detail.value!.toString(), 10))
                          }
                          label={t("stock")}
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>
                    </IonList>
                  </div>
                </IonAccordion>

                <IonAccordion value="fourth">
                  <IonItem slot="header" color="light">
                    <IonLabel> {t("shopping")}</IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <IonList>
                      <IonItem>
                        <IonLabel>
                          {" "}
                          {t("select-supplier-and-product-cost")}
                        </IonLabel>
                      </IonItem>

                      <IonItem>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                          label={t("supplier")}
                          labelPlacement="floating"
                        >
                          <IonSelectOption value="Empieza a escribir para buscar o crear una cuenta">
                            {t(
                              "Empieza a escribir para buscar o crear un contacto",
                            )}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonInput
                          type="number"
                          value={cost}
                          placeholder="00000.00"
                          onIonChange={(e) =>
                            setCost(parseInt(e.detail.value!.toString(), 10))
                          }
                          label={t("cost")}
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                          label={t("shopping-tax")}
                          labelPlacement="floating"
                        >
                          <IonSelectOption value="sales-tax 20%">
                            {t("sales-tax 20%")}
                          </IonSelectOption>
                          <IonSelectOption value="sales-tax 10%">
                            {t("sales-tax 20%")}
                          </IonSelectOption>
                          <IonSelectOption value="sales-tax 0%">
                            {t("sales-tax 0%")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonInput
                          type="number"
                          value={subtotal}
                          placeholder="00000.00"
                          onIonChange={(e) =>
                            setSubtotal(e.detail.value!.toString())
                          }
                          label={t("purchase-price-(Subtotal)")}
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>
                    </IonList>
                  </div>
                </IonAccordion>

                <IonAccordion value="fifth">
                  <IonItem slot="header" color="light">
                    <IonLabel> {t("categorization")}</IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <IonList>
                      <IonItem>
                        <IonLabel> {t("tags")}</IonLabel>
                      </IonItem>

                      <IonItem>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                          label={t("tags")}
                          labelPlacement="floating"
                        >
                          <IonSelectOption value="Empieza a escribir para buscar o crear un Tag">
                            {t("Empieza a escribir para buscar o crear un Tag")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonInput
                          type="number"
                          value={cost}
                          placeholder="00000.00"
                          onIonChange={(e) =>
                            setCost(parseInt(e.detail.value!.toString(), 10))
                          }
                          label={t("cost")}
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                          label={t("shopping-tax")}
                          labelPlacement="floating"
                        >
                          <IonSelectOption value="sales-tax 20%">
                            {t("sales-tax 20%")}
                          </IonSelectOption>
                          <IonSelectOption value="sales-tax 10%">
                            {t("sales-tax 20%")}
                          </IonSelectOption>
                          <IonSelectOption value="sales-tax 0%">
                            {t("sales-tax 0%")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonInput
                          type="number"
                          value={subtotal}
                          placeholder="00000.00"
                          onIonChange={(event) => {
                            if (event.detail.value === undefined) return;
                            setSubtotal(event.detail.value!.toString());
                          }}
                          label={t("purchase-price-(Subtotal)")}
                          labelPlacement="floating"
                        ></IonInput>
                      </IonItem>
                    </IonList>
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
            </IonCardContent>
            {false ? (
              <IonItem>
                <div className="spin">
                  <IonSpinner></IonSpinner>
                </div>
              </IonItem>
            ) : (
              image && (
                <IonItem>
                  <IonImg
                    style={{
                      maxWidth: "100%",
                      height: "250px",
                      width: "250px",
                    }}
                    src={`${image}`}
                  />
                </IonItem>
              )
            )}

            {false ? (
              <div className="spin">
                <IonSpinner></IonSpinner>
              </div>
            ) : (
              <IonButton
                fill="outline"
                expand="block"
                onClick={() => {
                  createBitacoraVisitanteSubmit();
                }}
              >
                <IonIcon icon={sendIcon} slot="end"></IonIcon>
                {t("create")}
              </IonButton>
            )}
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
};

export default CreateProductPage;
