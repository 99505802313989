import React, { useState } from 'react';
import { IonImg, IonSpinner } from '@ionic/react';

export const ImageComponent: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    // Image finished loading
    setLoading(false);
  };

  return (
    <div>
      {loading && <IonSpinner />} {/* Show spinner while loading */}
      <IonImg
        src="https://img.lovepik.com/png/20230930/weigh-girl-girls-scale-hospitals_40222_wh1200.png"
        onIonImgDidLoad={handleImageLoad}  // Set the handler for image load event
        style={{ maxWidth: '100%', height: '186px', width: '100%' }}
      />
    </div>
  );
};
