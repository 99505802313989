import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchUsers, selectUserLists } from "./userSlice";
import { IUser } from "./interface";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
} from "@ionic/react";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonAvatar,
} from "@ionic/react";

export const UsersListPage = () => {
  const dispatch = useAppDispatch();

  const usersList = useAppSelector(selectUserLists);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle size="large">Test</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <>
          {!usersList.isLoadingUsers && usersList.userList && (
            <IonList>
              <IonListHeader>Albums</IonListHeader>
              {usersList.userList.map((record: IUser) => (
                <IonItem key={record.id}>
                  <IonAvatar slot="start">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      alt="Silhouette of a person's head"
                      src="https://ionicframework.com/docs/img/demos/avatar.svg"
                    />
                  </IonAvatar>
                  {/*<IonThumbnail slot="start">
                  <IonIcon style={{ width: '100%', height: '100%' }} icon={musicalNotes} />
                </IonThumbnail>*/}
                  <IonLabel>
                    <h3>{record.name}</h3>
                    <p>{record.username}</p>
                    <p>{record.email}</p>
                    <p>{record.website}</p>
                    <p>
                      {record.address.street}, {record.address.city}
                    </p>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          )}
          {usersList.isLoadingUsers && (
            <IonList>
              <IonListHeader>
                <IonSkeletonText
                  animated={true}
                  style={{ width: "80px" }}
                ></IonSkeletonText>
              </IonListHeader>
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            </IonList>
          )}
          <IonButton onClick={() => setLoaded(!loaded)}>Toggle</IonButton>
        </>
      </IonContent>
    </IonPage>
  );
};
