import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonDatetime,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonSpinner,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonLabel,
  IonSelectOption,
  IonList,
  IonTextarea,
  IonBackButton,
  IonSelect,
  IonImg,
  IonAccordion,
  IonAccordionGroup,
  IonGrid,
  IonCol,
  IonRow,
  IonInput,
  IonCheckbox,
} from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import {
  send as sendIcon,
  attach as attachIcon,
  trashOutline,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useTranslation } from "react-i18next";
import "./Pages.css";



interface PageProps {
  pageName: string | undefined;
}

const CreateContactPage: React.FC<PageProps> = ({ pageName }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const hiddenFileInput = useRef(null);
  const [currentUser] = useState(AuthService.getCurrentUser());
  const [condominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0,
  );
  const [image, setImage] = useState<string>();
  const [file, setFile] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const [text, setText] = useState<string>();
  const [name, setName] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [cellPhone, setcellPhone] = useState<string>();
  const [fechaIncidencia, setFechaIncidencia] = useState<string>();
  const [incidencia, setIncidencia] = useState("");
  const [lang, setLang] = useState<string>(
    localStorage.getItem("langSelect")
      ? localStorage.getItem("langSelect") === "es"
        ? "es-ES"
        : "en-US"
      : "en-US",
  );
  const [basic, setBasic] = useState<string>();
  const [town, setTown] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [state, setState] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [tradename, setTradename] = useState<string>();
  const [website, setWebsite] = useState<string>();
  const [tags, setTags] = useState<string>();
  const [typeofcontact, setTypeContact] = useState<string>();
  const [idNumber, setIdNumber] = useState<string>();
  const [bank, setBank] = useState<string>();
  const [iban, setIban] = useState<string>();
  const [SWIFT, setSWIFT] = useState<string>();
  const [mandateReference, setMandateReference] = useState<string>();
  const [mandatedate, setMandateDate] = useState<string>();
  const [languaje, setLanguaje] = useState<string>();
  const [currency, setCurrency] = useState<string>();
  const [salesaccount, setSalesAccount] = useState<string>();
  const [shoppingaccount, setShoppingAccount] = useState<string>();
  const [methodofpayment, setMethodoofpayment] = useState<string>();
  const [expirationat, setExpirationAt] = useState<string>();
  const [discount, setDiscount] = useState<string>();
  const [rate, setRate] = useState<string>();
  const [payday, setPayday] = useState<string>();
  const [reference, setReference] = useState<string>();



  function setPreview(file: any) {
    setFile(file.target.files[0]);
    console.log("");
  }


  const onChangeFechaInicio = (dates: any) => {
    setFechaIncidencia(dates);
  };


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("contacts")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {false ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{t("new-contact")}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              <IonAccordionGroup>
                <IonAccordion value="first">
                  <IonItem slot="header" color="light">
                    <IonLabel> {t("basic")}</IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <IonList>
                      <IonItem>
                        <IonLabel position="floating">{t("name")}</IonLabel>
                        <IonInput
                          value={name}
                          onIonChange={(e) =>
                            setName(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("id-number")}
                        </IonLabel>
                        <IonInput
                          value={idNumber}
                          onIonChange={(e) =>
                            setIdNumber(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("address")}</IonLabel>
                        <IonInput
                          value={address}
                          onIonChange={(e) =>
                            setAddress(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("town")}</IonLabel>
                        <IonInput
                          value={town}
                          onIonChange={(e) =>
                            setTown(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("postal-code")}
                        </IonLabel>
                        <IonInput
                          value={postalCode}
                          onIonChange={(e) =>
                            setPostalCode(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("state")}</IonLabel>
                        <IonInput
                          value={state}
                          onIonChange={(e) =>
                            setState(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("country")}</IonLabel>
                        <IonInput
                          value={address}
                          onIonChange={(e) =>
                            setCountry(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("tradename")}
                        </IonLabel>
                        <IonInput
                          value={tradename}
                          onIonChange={(e) =>
                            setTradename(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("email")}</IonLabel>
                        <IonInput
                          value={email}
                          onIonChange={(e) =>
                            setEmail(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("phone")}</IonLabel>
                        <IonInput
                          value={phone}
                          onIonChange={(e) =>
                            setPhone(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("cell-phone")}
                        </IonLabel>
                        <IonInput
                          value={cellPhone}
                          onIonChange={(e) =>
                            setcellPhone(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("Website")}</IonLabel>
                        <IonInput
                          value={website}
                          onIonChange={(e) =>
                            setWebsite(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("tags")}</IonLabel>
                        <IonInput
                          value={tags}
                          onIonChange={(e) =>
                            setTags(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonInput>
                        <IonLabel> {t("type-of-contact")}</IonLabel>
                      </IonInput>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("type-of-contact")}
                        </IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                        >
                          <IonSelectOption value="supplier">
                            {t("supplier")}
                          </IonSelectOption>
                          <IonSelectOption value="client">
                            {t("client")}
                          </IonSelectOption>
                          <IonSelectOption value="unspecified">
                            {t("unspecified")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <input
                        ref={hiddenFileInput}
                        hidden
                        type="file"
                        accept="image/*"
                        onChange={(e) => setPreview(e)}
                        onClick={() => {
                          console.log("onClick");
                        }}
                      ></input>
                      <IonButton
                        fill="outline"
                        slot="start"
                        onClick={() => {
                          // @ts-ignore
                          hiddenFileInput?.current?.click();
                          // setBackgroundOption(BackgroundOptionType.Gradient);
                        }}
                      >
                        <IonIcon icon={attachIcon} slot="start" />
                        {t("select-img")}
                      </IonButton>
                    </IonList>
                  </div>
                </IonAccordion>

                <IonAccordion value="second">
                  <IonItem slot="header" color="light">
                    <IonLabel> {t("banks")}</IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <IonList>
                      <IonItem>
                        <IonLabel position="floating">{t("bank")}</IonLabel>
                        <IonInput
                          value={bank}
                          onIonChange={(e) =>
                            setBank(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("IBAN")}</IonLabel>
                        <IonInput
                          value={iban}
                          onIonChange={(e) =>
                            setIban(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("SWIFT")}</IonLabel>
                        <IonInput
                          value={SWIFT}
                          onIonChange={(e) =>
                            setSWIFT(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonInput>
                        <IonLabel> {t("SEPA Remittances")}</IonLabel>
                      </IonInput>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("mandate-reference")}
                        </IonLabel>
                        <IonInput
                          value={mandateReference}
                          onIonChange={(e) =>
                            setMandateReference(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("mandate-date")}
                        </IonLabel>
                        <IonInput
                          value={mandatedate}
                          onIonChange={(e) =>
                            setMandateDate(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonGrid>
                        <IonRow>
                          <IonCol style={{ border: "0px" }}>
                            <IonItem>
                              <IonCheckbox slot="start"></IonCheckbox>
                              <IonLabel>{t("default-bank")}</IonLabel>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonGrid>

                      <IonGrid>
                        <IonRow>
                          <IonCol style={{ border: "0px" }}>
                            <IonItem>
                              <IonButton slot="end" fill="clear">
                                <IonIcon icon={trashOutline} slot="icon-only" />
                              </IonButton>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonList>
                  </div>
                </IonAccordion>

                <IonAccordion value="third">
                  <IonItem slot="header" color="light">
                    <IonLabel> {t("preferences")}</IonLabel>
                  </IonItem>

                  <div className="ion-padding" slot="content">
                    <IonList>
                      <IonItem>
                        <IonLabel position="floating">{t("languaje")}</IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select-languaje")}
                        >
                          <IonSelectOption value="spanish">
                            {t("spanish")}
                          </IonSelectOption>
                          <IonSelectOption value="english">
                            {t("english")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("currency")}</IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("currency")}
                        >
                          <IonSelectOption value="mexican-peso">
                            {t("mexican-peso")}
                          </IonSelectOption>
                          <IonSelectOption value="dollar">
                            {t("dollar")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("sales-account")}
                        </IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select-account")}
                        >
                          <IonSelectOption value="mexican-peso">
                            {t("mexican-peso")}
                          </IonSelectOption>
                          <IonSelectOption value="dollar">
                            {t("dollar")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("shopping-account")}
                        </IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select-account")}
                        >
                          <IonSelectOption value="mexican-peso">
                            {t("mexican-peso")}
                          </IonSelectOption>
                          <IonSelectOption value="dollar">
                            {t("dollar")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("reference")}
                        </IonLabel>
                        <IonInput
                          value={reference}
                          onIonChange={(e) =>
                            setReference(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("method-of-payment")}
                        </IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                        >
                          <IonSelectOption value="unselected">
                            {t("unselected")}
                          </IonSelectOption>
                          <IonSelectOption value="wire-transfer">
                            {t("wire-transfer")}
                          </IonSelectOption>
                          <IonSelectOption value="cash-register">
                            {t("cash-register")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("expiration-at")}
                        </IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                        >
                          <IonSelectOption value="same-day">
                            {t("same-day")}
                          </IonSelectOption>
                          <IonSelectOption value="7-days">
                            {t("7-days")}
                          </IonSelectOption>
                          <IonSelectOption value="15-days">
                            {t("15-days")}
                          </IonSelectOption>
                          <IonSelectOption value="30-days">
                            {t("30-days")}
                          </IonSelectOption>
                          <IonSelectOption value="60-days">
                            {t("60-days")}
                          </IonSelectOption>
                          <IonSelectOption value="personalize">
                            {t("personalize")}{" "}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("discount-%")}
                        </IonLabel>
                        <IonInput
                          value={discount}
                          onIonChange={(e) =>
                            setDiscount(e.detail.value!.toString())
                          }
                        ></IonInput>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("rate")}</IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                        >
                          <IonSelectOption value="unselected">
                            {t("unselected")}
                          </IonSelectOption>
                          <IonSelectOption value="1">{t("1")}</IonSelectOption>
                          <IonSelectOption value="2">{t("2")}</IonSelectOption>
                          <IonSelectOption value="3">{t("3")}</IonSelectOption>
                          <IonSelectOption value="31">
                            {t("31")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonGrid>
                        <IonRow>
                          <IonCol style={{ border: "0px" }}>
                            <IonItem>
                              <IonCheckbox slot="start"></IonCheckbox>
                              <IonLabel>
                                {t("show-business-name-on-invoices")}
                              </IonLabel>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonGrid>

                      <IonGrid>
                        <IonRow>
                          <IonCol style={{ border: "0px" }}>
                            <IonItem>
                              <IonCheckbox slot="start"></IonCheckbox>
                              <IonLabel>
                                {t("show-country-on-invoices")}
                              </IonLabel>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonGrid>

                      <IonInput>
                        <IonLabel> {t("assign-default-number-lines")}</IonLabel>
                      </IonInput>

                      <IonInput>
                        <IonLabel> {t("e-invoice-fields")}</IonLabel>
                      </IonInput>
                    </IonList>
                  </div>
                </IonAccordion>

                <IonAccordion value="fourth">
                  <IonItem slot="header" color="light">
                    <IonLabel> {t("accounting")}</IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <IonList>
                      <IonInput>
                        <IonLabel> {t("taxes")}</IonLabel>
                      </IonInput>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("sales-tax")}
                        </IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                        >
                          <IonSelectOption value="sales-tax 20%">
                            {t("sales-tax 20%")}
                          </IonSelectOption>
                          <IonSelectOption value="sales-tax 10%">
                            {t("sales-tax 20%")}
                          </IonSelectOption>
                          <IonSelectOption value="sales-tax 0%">
                            {t("sales-tax 0%")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonInput>
                        <IonLabel> {t("ID-number")}</IonLabel>
                      </IonInput>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("customer/debtor-account")}
                        </IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                        >
                          <IonSelectOption value="Empieza a escribir para buscar o crear una cuenta">
                            {t(
                              "Empieza a escribir para buscar o crear una cuenta",
                            )}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">{t("others")}</IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                        >
                          <IonSelectOption value="General">
                            {t("General")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("purchase-taxes")}
                        </IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                        >
                          <IonSelectOption value="sales-tax 20%">
                            {t("sales-tax 20%")}
                          </IonSelectOption>
                          <IonSelectOption value="sales-tax 10%">
                            {t("sales-tax 20%")}
                          </IonSelectOption>
                          <IonSelectOption value="sales-tax 0%">
                            {t("sales-tax 0%")}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="floating">
                          {t("vendor/creditor-account")}
                        </IonLabel>
                        <IonSelect
                          interface="popover"
                          placeholder={t("select")}
                        >
                          <IonSelectOption value="Empieza a escribir para buscar o crear una cuenta">
                            {t(
                              "Empieza a escribir para buscar o crear una cuenta",
                            )}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
            </IonCardContent>
            {false ? (
              <IonItem>
                <div className="spin">
                  <IonSpinner></IonSpinner>
                </div>
              </IonItem>
            ) : (
              image && (
                <IonItem>
                  <IonImg
                    style={{
                      maxWidth: "100%",
                      height: "250px",
                      width: "250px",
                    }}
                    src={`${image}`}
                  />
                </IonItem>
              )
            )}

            {false ? (
              <div className="spin">
                <IonSpinner></IonSpinner>
              </div>
            ) : (
              <IonButton
                fill="outline"
                expand="block"
              >
                <IonIcon icon={sendIcon} slot="end"></IonIcon>
                {t("create")}
              </IonButton>
            )}
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
};

export default CreateContactPage;
