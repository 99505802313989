const env = {
  development: {
    BASE_URL: "https://jsonplaceholder.typicode.com/",
    USER_NAME: "j.krrishchandran@gmail.com",
    PASSWORD: "samplereact",
    BACKEND_URL: "http://127.0.0.1:8000/"
  },
  production: {
    BASE_URL: "https://jsonplaceholder.typicode.com/",
    USER_NAME: "j.krrishchandran@gmail.com",
    PASSWORD: "samplereact",
    BACKEND_URL: "http://127.0.0.1:8000/"
  },
  test: {
    BASE_URL: "http://127.0.0.1:8000/",
    USER_NAME: "j.krrishchandran@gmail.com",
    PASSWORD: "samplereact",
    BACKEND_URL: "http://127.0.0.1:8000/"
  },
};

export const env_var = env[process.env.NODE_ENV];
