import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
  persistReducer
} from 'redux-persist';
import { authenticationReducer } from "./pages/auth/login/loginSlice";
import { usersListReducer } from "./pages/Users-List/userSlice";
import { materialsListReducer } from "./pages/Materiales-List/materialSlice";
import persistConfig from './persistConfig';

// Combine reducers if you have multiple reducers
const rootReducer = combineReducers({
  authentication: authenticationReducer,
  userList: usersListReducer,
  materialList: materialsListReducer,
});

// Apply persistReducer to the rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer, // Use persistedReducer here
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
