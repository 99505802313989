import { useHistory } from "react-router-dom";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonItem,
  IonIcon,
  IonSpinner,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonLabel,
  IonInput,
  IonToggle,
  useIonViewWillEnter,
  IonItemDivider,
  IonList,
  IonSelect,
  IonSelectOption,
  IonListHeader,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import type { ToggleCustomEvent } from "@ionic/react";
import {
  personCircle,
  personCircleOutline,
  sunny,
  sunnyOutline,
} from "ionicons/icons";

import {
  key as keyIcon,
  language as languageIcon,
  moon as moonIcon,
} from "ionicons/icons";
import "moment/locale/es";
import AuthService from "../services/auth.service";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import "./Pages.css";


interface PageProps {
  pageName: string | undefined;
}

const ConfiguracionPage: React.FC<PageProps> = ({ pageName }, location) => {
  const { t, i18n } = useTranslation();
  const [currentUser] = useState({ condominioId: 1, id: 1 });
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const [lang, setLang] = useState<string>(
    localStorage.getItem("langSelect")
      ? localStorage.getItem("langSelect")!
      : "en",
  );
  const [text, setText] = useState<string>();
  const [isDarkModeEnable, setIsDarkMode] = useState(
    AuthService.isDarkModeEnable(),
  );

  const [paletteToggle, setPaletteToggle] = useState(false);

  // Listen for the toggle check/uncheck to toggle the dark palette
  const toggleChange = (ev: ToggleCustomEvent) => {
    toggleDarkPalette(ev.detail.checked);
  };

  // Add or remove the "ion-palette-dark" class on the html element
  const toggleDarkPalette = (shouldAdd: boolean) => {
    document.documentElement.classList.toggle("ion-palette-dark", shouldAdd);
  };

  // Check/uncheck the toggle and update the palette based on isDark
  const initializeDarkPalette = (isDark: boolean) => {
    setPaletteToggle(isDark);
    toggleDarkPalette(isDark);
  };

  useEffect(() => {
    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

    // Initialize the dark palette based on the initial
    // value of the prefers-color-scheme media query
    initializeDarkPalette(prefersDark.matches);

    const setDarkPaletteFromMediaQuery = (mediaQuery: MediaQueryListEvent) => {
      initializeDarkPalette(mediaQuery.matches);
    };

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addEventListener("change", setDarkPaletteFromMediaQuery);

    return () => {
      prefersDark.removeEventListener("change", setDarkPaletteFromMediaQuery);
    };
  }, []);

  const toggleDarkModeHandler = () => {
    if (!isDarkModeEnable) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    setIsDarkMode(!isDarkModeEnable);
    localStorage.setItem("darkmode", JSON.stringify(!isDarkModeEnable));
    //document.body.classList.toggle("dark");
  };

  const changeLanguage = (lng: string) => {
    setLang(lng);
    localStorage.setItem("langSelect", lng);
    i18n.changeLanguage(lng);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("settings")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {false ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <>
            <IonCard>
              <IonList>
                <IonItem>
                  <IonListHeader>Appearance</IonListHeader>
                  <IonList inset={true}>
                    <IonItem>
                      <IonToggle
                        checked={paletteToggle}
                        onIonChange={toggleChange}
                        justify="space-between"
                      >
                        Dark Mode
                      </IonToggle>
                    </IonItem>
                  </IonList>
                </IonItem>
                <IonItemDivider>{t("password-information")}</IonItemDivider>
                <IonItem>
                  <IonLabel position="floating">{t("old-password")}</IonLabel>
                  <IonInput
                    value={text}
                    placeholder={t("old-password")}
                    type={"password"}
                    onIonChange={(e) => setText(e.detail.value!.toString())}
                  ></IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel position="floating">{t("new-password")}</IonLabel>
                  <IonInput
                    value={text}
                    placeholder={t("new-password")}
                    type={"password"}
                    onIonChange={(e) => setText(e.detail.value!.toString())}
                  ></IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel position="floating">
                    {t("confirm-new-password")}
                  </IonLabel>
                  <IonInput
                    value={text}
                    placeholder={t("confirm-new-password")}
                    type={"password"}
                    onIonChange={(e) => setText(e.detail.value!.toString())}
                  ></IonInput>
                </IonItem>
                <IonButton
                  fill="outline"
                  expand="block"
                  routerLink={"/page/tickets/create"}
                >
                  <IonIcon icon={keyIcon} slot="start" />
                  {t("update-password")}
                </IonButton>
              </IonList>
            </IonCard>
            <IonCard>
              <IonList>
                <IonItemDivider>{t("lang-information")}</IonItemDivider>
                <IonSelect
                  value={lang}
                  placeholder="Seleccione una opción"
                  onIonChange={(ev) => changeLanguage(ev.detail.value!)}
                >
                  <IonSelectOption value={"es"}>{"Español"}</IonSelectOption>
                  <IonSelectOption value={"en"}>{"Ingles"}</IonSelectOption>
                </IonSelect>
                <IonButton
                  fill="outline"
                  expand="block"
                  routerLink={"/page/tickets/create"}
                >
                  <IonIcon icon={languageIcon} slot="start" />
                  {t("update-preferred-language")}
                </IonButton>
              </IonList>
            </IonCard>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ConfiguracionPage;
