import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUserList } from "../../services/userService";
import { RootState } from "../../store";
import { IUser } from "./interface";

export interface IUsersList {
  isLoadingUsers: boolean;
  userList?: IUser[];
  error?: string;
}

const initialState: IUsersList = { isLoadingUsers: false };

const userListSlice = createSlice({
  name: "userList",
  initialState,
  reducers: {
    start: (state) => {
      state.isLoadingUsers = true;
      state.error = undefined;
    },
    success: (state, action: PayloadAction<IUser[]>) => {
      state.userList = action.payload;
      state.isLoadingUsers = false;
      state.error = undefined;
    },
    error: (state, action: PayloadAction<string>) => {
      state.isLoadingUsers = false;
      state.error = action.payload;
    },
  },
});

export const fetchUsers = () => async (dispatch: any) => {
  dispatch(start());
  try {
    const userLists = await getUserList();
    dispatch(success(userLists));
  } catch (err) {
    if (err instanceof Error) {
      dispatch(error(err.message));
    } else {
      dispatch(error("An unknown error occurred."));
    }
  }
};

export const { start, success, error } = userListSlice.actions;

export const selectUserLists = (state: RootState) => state.userList;

export const usersListReducer = userListSlice.reducer;
