import React, { useState, PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

import {
  IonButton,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRouterLink,
  IonRow,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
} from "@ionic/react";
import { Action } from "../components/Action";
import { add as addIcon, send as sendIcon } from "ionicons/icons";

import { useTranslation } from "react-i18next";
const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Home = () => {
  const [name, setName] = useState("");


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons>
            <IonMenuButton />
            <IonTitle>{"Home"}</IonTitle>
            <IonButton slot="end" fill="clear" routerLink={"/page/home/new"}>
              <IonIcon icon={addIcon} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <b>Columns aligned at the top asas</b>
        <IonGrid>
          <IonRow class="ion-align-items-start">
            <IonCol><PieChart width={400} height={400}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={180}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart></IonCol>
            <IonCol>2</IonCol>
            <IonCol>3</IonCol>
            <IonCol>
              4 <br />
              # <br />
              # <br />
              # <br />
            </IonCol>
          </IonRow>
        </IonGrid>

        <b>Columns aligned at the center</b>
        <IonGrid>
          <IonRow class="ion-align-items-center">
            <IonCol>
              <PieChart width={400} height={400}>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  //label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </IonCol>
            <IonCol>2</IonCol>
            <IonCol>3</IonCol>
            <IonCol>
              4 <br />
              # <br />
              # <br />
              # <br />
            </IonCol>
          </IonRow>
        </IonGrid>

        <b>Columns aligned at the bottom</b>
        <IonGrid>
          <IonRow class="ion-align-items-end">
            <IonCol>1</IonCol>
            <IonCol>2</IonCol>
            <IonCol>3</IonCol>
            <IonCol>
              4 <br />
              # <br />
              # <br />
              # <br />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonFooter>
        <IonGrid>
          <Action
            message="This is the bottom page"
            text="Go to"
            link="/login"
          />
        </IonGrid>
      </IonFooter>
    </IonPage>
  );
};

export default Home;
