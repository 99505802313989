import React, { useEffect, useState } from "react";
import { useIonViewDidEnter } from '@ionic/react';
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchMaterials, selectMaterialLists } from "./materialSlice";
import { IMaterial } from "./interface";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonImg
} from "@ionic/react";
import {ImageComponent} from "../../components/ImageComponent";


export const MaterialsListPage = () => {
  const dispatch = useAppDispatch();

  const materialsList = useAppSelector(selectMaterialLists);
  const [loading, setLoading] = useState(true);

  /*useEffect(() => {
    dispatch(fetchMaterials());
  }, [dispatch]);*/

  useIonViewDidEnter(() => {
    dispatch(fetchMaterials());
  });

  const handleImageLoad = () => {
    // Image finished loading
    setLoading(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle size="large">Materiales</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <>
          {!materialsList.isLoadingMaterials && materialsList.materialList && (
            <IonList>
              <IonListHeader>Lista de materiales</IonListHeader>
              {materialsList.materialList.map((record: IMaterial) => (
                <>
                  <IonCard onClick={() => alert('Test')} key={record.id}>
                    <ImageComponent/>
                    <IonCardHeader>
                      <IonCardTitle>{record.nombre[0]}</IonCardTitle>
                      <IonCardSubtitle>{record.linea}</IonCardSubtitle>
                    </IonCardHeader>

                    <IonCardContent>{record.unidadmedidaentrada}</IonCardContent>
                  </IonCard>
                </>
              ))}
            </IonList>
          )}
          {materialsList.isLoadingMaterials && (
            <IonList>
              <IonListHeader>
                <IonSkeletonText
                  animated={true}
                  style={{ width: "80px" }}
                ></IonSkeletonText>
              </IonListHeader>
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            </IonList>
          )}
        </>
      </IonContent>
    </IonPage>
  );
};
