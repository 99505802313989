import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonLabel,
  IonList,
  IonInput,
  IonItem,
  IonImg,
  IonSpinner,
  useIonToast,
  IonInputPasswordToggle,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
} from "@ionic/react";
import { logIn as logInIcon, closeCircle as closeIcon } from "ionicons/icons";
import { useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../../../services/auth.service";
import { authenticateUser } from "./loginSlice";
import { useAppDispatch } from "../../../hooks";
import { isAuthenticated } from "../../../services/authenticationService";
import { useHistory } from "react-router-dom";
import { OverlayEventDetail } from "@ionic/core/components";
import ResetPassword from "../../../components/auth/ResetPassword";
//import LogoComponent from '../../../components/LogoComponent';
import { useIonRouter } from '@ionic/react';



const LoginPage: React.FC = () => {
  const [present] = useIonToast();
  const loggedIn = AuthService.loggedIn();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const routeRedirect = document.createElement("ion-route-redirect");
  routeRedirect.setAttribute("from", "/login");
  routeRedirect.setAttribute("to", "/page/anuncios");
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const router = useIonRouter();

  const dispatch = useAppDispatch();
  let history = useHistory();
  if (isAuthenticated()) {
    //history.push("/v1");
  }

  const presentToast = (position: 'top' | 'middle' | 'bottom', color: 'success' | 'warning' | 'danger', message: string) => {
    present({
      message: message,
      duration: 1500,
      position: position,
      color: color

    });
  };

  const onFinish = (values: any) => {
    dispatch(authenticateUser(values, router));
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      let data = {
        email: email,
        password: password,
      };
      onFinish(data);
      presentToast('bottom', 'success', '');
      setLoading(false);
    } catch (error: any) {
      presentToast('bottom', 'danger', error.message);
      setLoading(false);
    }
  };

  const login = () => {
    let data = {
      email: email,
      password: password,
    };
    onFinish(data);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      let data = {
        email: email,
        password: password,
      };
      onFinish(data);
    }
  };

  const [message, setMessage] = useState(
    "This modal example uses triggers to automatically open a modal when the button is clicked.",
  );

  if (isAuthenticated()) {
    //return <Redirect to="/v1" />;
  }

  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <IonGrid className="ion-padding" fixed={true}>
          <IonRow class="ion-justify-content-center">
            <IonCol style={{ border: "0px" }}>
              <div className="center">
                {/*<LogoComponent isDarkMode/>*/}
                <IonImg
                  style={{
                    width: "20%",
                    margin: "auto",
                    display: "block",
                    minWidth: "200px",
                  }}
                  alt="Silhouette of a person's head"
                  src="/assets/images/logo-san-gabriel-header.png"
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow class="ion-justify-content-center">
            <IonCol style={{ border: "0px" }}>
              <div className="ion-text-center">
                {/*<IonCardTitle>Exportadora de Cítricos San Gabriel S.A. de C.V.</IonCardTitle>*/}

                <h5>Bienvenido</h5>
              </div>
            </IonCol>
          </IonRow>

          <IonRow className="ion-margin-top ion-padding-top ion-justify-content-center">
            <IonCol
              size="12"
              size-xl="6"
              size-lg="6"
              size-md="10"
              size-sm="10"
              style={{ border: "0px" }}
            >
              <form onSubmit={onSubmit}>
                <IonList>
                  <IonItem>
                    <IonLabel position="stacked">Correo Electrónico</IonLabel>
                    <IonInput
                      id="email"
                      type="email"
                      value={email}
                      onKeyDown={handleKeyDown}
                      onIonChange={(e) => setEmail(e.detail.value!)}
                      required
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Contraseña</IonLabel>
                    <IonInput
                      type="password"
                      value={password}
                      onIonChange={(e) => setPassword(e.detail.value!)}
                      onKeyDown={handleKeyDown}
                      required
                    >
                      <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
                    </IonInput>
                  </IonItem>
                  <IonRow class="ion-justify-content-end">
                    <IonCol size="6" style={{ border: "0px" }}>
                      <IonButton id="open-modal" fill="clear">
                        Forgot Password?
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonList>

                {loading ? (
                  <div className="spin">
                    <IonSpinner></IonSpinner>
                  </div>
                ) : (
                  <IonButton
                    className="custom-button"
                    expand="block"
                    fill="outline"
                    type="submit"
                  >
                    <IonIcon icon={logInIcon} slot="end" />
                    Iniciar sesión
                  </IonButton>
                )}
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonModal
          ref={modal}
          trigger="open-modal"
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => modal.current?.dismiss()}>
                  <IonIcon icon={closeIcon} slot="start" />
                </IonButton>
              </IonButtons>
              <IonTitle>Reestablecer contraseña</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <ResetPassword handleClose={() => modal.current?.dismiss()} ></ResetPassword>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
