import { get, post, getWithRouteParams } from "./apiService";
import { env_var } from "../config/env";

export const getMaterialList = async function () {
  return await get(`${env_var.BACKEND_URL}materiales`);
};

export const forgotPassword = async function (body: any) {
  return await post(`materials/forgot_password`, body);
};

export const resetPassword = async function (token: string) {
  return await getWithRouteParams(`reset_password/${token}`);
};

export const materialLogin = async function (body: any) {
  return await post(`materials/login`, body);
};


